/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'components/atoms/Typography';
import { Button } from 'components/atoms/Button';
import { BaseModalDownload } from 'components/molecules/Modal';
import messages from 'containers/Home/messages';
import { ContentWrapper, IndentedText } from './wrappers';

// Define the link component outside to avoid re-creating it every render
const renderLink = (chunks) => (
  <a href="https://s420l.club/static/uninstall_instructions.pdf" target="_blank" rel="noopener noreferrer">
    {chunks}
  </a>
);

const ConfirmDownloadModal = ({ isOpen, onClose, message, confirmDownload, results, ...props }) => (
  <BaseModalDownload {...{ isOpen, onClose, ...props }}>
    <ContentWrapper>
    <FormattedMessage {...messages.installHeader} />
    <div style={{ width: "100%", textAlign: "center" }}>
      <hr
        style={{
          width: "100%",
          border: "1px solid black",
          margin: "20px 0",
          height: "0px", // Ensure the height doesn't cause distortion
        }}
      />
    </div>
      <IndentedText>
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {[
            "{startMessage}",
            "{count} books in total :D",
            "It will take about {minutes} minutes if your internet is fast. The larger the book selection and/or the slower the internet connection, the longer it will take.",
            "Hit cancel to cancel download or change the selection of books.",
            "Once the app is installed and the books are downloaded, you will be able to use the app with or without the internet!",
            "If you already have the app and would like to update, please uninstall the old version first (<a>uninstall instructions</a>).",
            "Please be aware that the app will not download on IOS devices. This is not our fault, but due to anticompetitive behavior by Apple for which they are currently being sued by the US Government.",
            "Depending on what device you're on you may have to submit this form a second time (sorry, still working on fixing this issue :3)"
          ].map((msg, index) => (
            <Typography
              key={index}
              variant="text-lg"
              component="li"
              style={{ whiteSpace: 'pre-wrap', textAlign: 'left', marginBottom: '0.5em' }}
              label={
                <FormattedMessage
                  id={`app.containers.Nav.ConfirmDownload.message${index}`}
                  values={{
                    count: results.count,
                    minutes: Math.round(results.count / 60),
                    startMessage: message,
                    a: renderLink
                  }}
                  defaultMessage={`⁃ ${msg}`}
                />
              }
            />
          ))}
        </ul>
      </IndentedText>
      <div id="yourdiv">
        <Button
          variant="download"
          label={<FormattedMessage {...messages.download} />}
          onClick={confirmDownload}
        />
      </div>
    </ContentWrapper>
  </BaseModalDownload>
);

ConfirmDownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  confirmDownload: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired,
};

export default ConfirmDownloadModal;