/* eslint-disable */
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const PageWrapper = styled.div`
  transition: all 300ms ease;

  > div > canvas {
    width: auto !important;
    height: auto !important;
    max-width: 108vw !important;
    transition: all 300ms ease;
    margin: 0 auto;
    margin-top: -30px;
    z-index: 0;

    /* DESKTOP STYLEZZZ */
  @media (min-width: 1370px) {
    ${({ openBook }) => {
      if (openBook.publisher === "pepperandcarrot") {
        return `
          width: auto !important;
          height: 100vh !important;
        `;
      } else if (openBook.publisher === "Mustard Seed") {
        return `
          width: auto !important;
          height: 120vh !important;
          margin-top: -120px;
        `;
      } else if (openBook.publisher === "storyweaver") {
        return `
          width: auto !important;
          height: 110vh !important;
          margin-top: -50px;
        `;
      } else if (openBook.publisher === "africanstorybook") {
        return `
          width: auto !important;
          height: 130vh !important;
          margin-top: -90px;
        `;
      } else {
        return `
          width: auto !important;
          height: 100vh !important;
        `;
      }
    }}
  }

    /* TABLET STYLES */
    @media only screen and (min-width: 768px) and (max-width: 1369px) {
      @media (orientation: landscape) {
        ${({ openBook }) => {
          if (openBook.publisher === "pepperandcarrot") {
            return `
              width: auto !important;
              height: 100vh !important;
            `;
          } else if (openBook.publisher === "Mustard Seed") {
            return `
              width: auto !important;
              height: 115vh !important;
              max-height: 115vh !important;
              margin-top: -110px;
            `;
          } else if (openBook.publisher === "storyweaver") {
            return `
              width: auto !important;
              height: 100vh !important;
            `;
          } else if (openBook.publisher === "africanstorybook") {
            return `
              width: auto !important;
              height: 100vh !important;
            `;
          } else {
            return `
              width: auto !important;
              height: 100vh !important;
            `;
          }
        }}
      }
        @media (orientation: portrait) {
        ${({ openBook }) => {
          if (openBook.publisher === "pepperandcarrot") {
            return `
              width: auto !important;
              height: 100vh !important;
            `;
          } else if (openBook.publisher === "Mustard Seed") {
            return `
              width: 110vw !important;
              max-width: 110vw !important;
              height: auto !important;
              margin-left: -60px;
            `;
          } else if (openBook.publisher === "storyweaver") {
            return `
              width: 100vw !important;
              height: auto !important;
            `;
          } else if (openBook.publisher === "africanstorybook") {
            return `
              width: 100vw !important;
              height: auto !important;
            `;
          } else {
            return `
              width: 100vw !important;
              height: auto !important;
              margin-top: -30px;
            `;
          }
        }}
      }
    }
      /* !!! PHONE !!! (ring ring MFer) !!! */
    @media only screen and (max-width: 767px) {
      @media (orientation: landscape) {
        ${({ openBook }) => {
          if (openBook.publisher === "pepperandcarrot") {
            return `
              width: auto !important;
              height: 100vh !important;
            `;
          } else if (openBook.publisher === "Mustard Seed") {
            return `
              width: auto !important;
              height: 110vh !important;
              max-height: 115vh !important;
              margin-top: -69px;
            `;
          } else if (openBook.publisher === "storyweaver") {
            return `
              width: auto !important;
              height: 105vh !important;
              margin-top: -45px;
            `;
          } else if (openBook.publisher === "africanstorybook") {
            return `
              width: auto !important;
              height: 115vh !important;
              max-height: 115vh !important; 
              mrgin-top: -40px;
            `;
          } else {
            return `
              width: auto !important;
              height: 100vh !important;
            `;
          }
        }}
      }
        @media (orientation: portrait) {
        ${({ openBook }) => {
          if (openBook.publisher === "pepperandcarrot") {
            return `
              width: auto !important;
              height: 100vh !important;
              margin-left: -12px;
            `;
          } else if (openBook.publisher === "Mustard Seed") {
            return `
              width: 105vw !important;
              max-width: 110vw !important;
              height: auto !important;
              margin-left: -10px;
            `;
          } else if (openBook.publisher === "storyweaver") {
            return `
              width: 105vw !important;
              height: auto !important;
              margin-top: -10px;
              margin-left: -15px;
            `;
          } else if (openBook.publisher === "africanstorybook") {
            return `
              width: 105vw !important;
              height: auto !important;
              margin-left: -10px;
            `;
          } else {
            return `
              width: 100vw !important;
              height: auto !important;
              margin-top: -30px;
            `;
          }
        }}
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  height: 100dvh;
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderWrapper = styled.nav`
  height: 40px;
  width: auto;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > button {
    margin: 0 8px;
  }
`;